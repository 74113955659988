.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.Button-container {
  justify-content: center;
}

.ant-tabs-left > .ant-tabs-nav {
  height: 100%; /* Asegura que la navegación de tabs ocupe toda la altura */
  display: flex;
  flex-direction: column;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list {
  flex: 1; /* Permite que las pestañas ocupen el espacio disponible */
  overflow-y: auto; /* Habilita el desplazamiento vertical */
}

.ant-tabs-left > .ant-tabs-content-holder {
  height: 100%; /* Asegura que el contenido también ocupe toda la altura */
  overflow-y: auto; /* Habilita el desplazamiento si es necesario */
}

.ant-tabs-content {
  height: 100%; /* Contenido completo dentro del área disponible */
}

/* Estilo para el ítem seleccionado en el menú */
.ant-menu-item-selected {
  background-color: #f0f0f0 !important; /* Color de fondo gris claro */
  color: rgba(0, 0, 0, 0.85) !important; /* Mismo color que al pasar el mouse */
}

/* Quitar la línea azul del borde izquierdo */
.ant-menu-item-selected::after {
  border: none !important;
}

/* Hover y focus para consistencia */
.ant-menu-item:hover {
  background-color: #f0f0f0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
}